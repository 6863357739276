// export const baseUrl = "http://localhost:8181/api/v1/"; /// Local
export const baseUrl = "https://staging.ina17.com/nsp"; /// Staging
// export const baseUrl = "https://cms.tselpvdo.id/api/v1/"; /// Production
// export const baseUrl = "http://10.10.2.188:8282/wap";

// PAYMENT TEXT
export const PAYMENT_TEXT = "Pembayaran 1x";
// export const urlService = "http://localhost:3000/tsel/";
// export const urlService = "https://staging.ina17.com/cms-vrbt/";
export const urlService = "https://cms.tselpvdo.id/api/v1/";

// BASE PATH
export const basePath = "pages/";
export const basePathVrbt = "pages/vrbt/";
export const baseTsel = "tsel";

export const baseVrbt = "/ios-vnsp-webview/"; /// staging
// export const baseVrbt = "/"; /// production/
export const BASE_PATH_IMAGE = process.env.PUBLIC_URL + "/images/";
export const BASE_PATH_THUMBNAIL =
  "https://staging.ina17.com/nsp/public/thumbnail/";
export const BASE_PATH_VIDEO = "https://staging.ina17.com/nsp/public/video/";

// status hide
export const StatusHide = false;

/// export report
export const report_value = [
  { id: 0, name: "Export Report", value: "" },
  { id: 1, name: "Excel", value: "excel" },
  { id: 2, name: "PDF", value: "pdf" },
];

/// status content
export const status_content = [
  {
    id: "2",
    label: "Waiting for Admin",
    color: "text-[#FF8000]",
    background: "bg-[#FF80001A]",
  },
  {
    id: "1",
    label: "Active",
    color: "text-[#008F1F]",
    background: "bg-[#06B21A40]",
  },
  {
    id: "0",
    label: "Inactive",
    color: "text-black",
    background: "bg-red-300",
  },
  {
    id: "3",
    label: "Rejected",
    color: "text-black",
    background: "bg-red-300",
  },
  {
    id: "4",
    label: "Stopped",
    color: "text-black",
    background: "bg-red-300",
  },
];

/// contact detail
export const contact_detail = {
  vrbt_email: "support@tselpvdo.id",
  vrbt_wa: "0811-10100936",
  vrbt_phone: "0811-10100936",
  vrbt_cs: "0811-10100936",

  // tsel
  telco_email: "support@tsel.com",
  telco_wa: "08213456780",
  telco_phone: "0210976544",
  telco_cs: "0821456789",
};

/// status type
export const content_type = [
  {
    id: 1,
    name: "Premium",
    value: "PREMIUM",
  },
  // {
  //   id: 2,
  //   name: "Regular",
  //   value: "REGULAR",
  // },
  // {
  //   id: 3,
  //   name: "Unlimited",
  //   value: "UNLIMITED",
  // },
];
