import { createSlice } from "@reduxjs/toolkit";

const contentGroupSlice = createSlice({
  name: "content_group",
  initialState: {
    data: [],
  },
  reducers: {
    setContentGroup: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setContentGroup } = contentGroupSlice.actions;
export default contentGroupSlice.reducer;
