import { remoteConfig } from "app/firebase";
import CryptoJS from "crypto-js";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { jwtDecode } from "jwt-decode";

export const ENCRYPTED_ID = "encryptedID";
export const ENCRYPTED_PHONE_NUMBER_COOKIE = "encryptedPhoneNumber";
export const ENCRYPTED_DATA_COOKIE = "encryptedData";
export const TOKEN_COOKIE = "authToken";

export const saveEncryptedData = (data) => {
  localStorage.setItem(ENCRYPTED_DATA_COOKIE, data);
};

export const saveEncryptedPhoneNumber = (data) => {
  localStorage.setItem(ENCRYPTED_PHONE_NUMBER_COOKIE, data);
};

export const saveEncryptedID = (data) => {
  localStorage.setItem(ENCRYPTED_ID, data);
};

export const saveToken = (token) => {
  localStorage.setItem(TOKEN_COOKIE, token);
};

export const getEncryptedPhoneNumber = () => {
  return localStorage.getItem(ENCRYPTED_PHONE_NUMBER_COOKIE);
};

export const getEncryptedData = () => {
  return localStorage.getItem(ENCRYPTED_DATA_COOKIE);
};

export const getEncryptedID = () => {
  return localStorage.getItem(ENCRYPTED_ID);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_COOKIE);
};

export const isTokenExpired = () => {
  const token = getToken();
  if (!token) return true;

  try {
    const decodedToken = jwtDecode(token);
    // Add a 10-second buffer to handle network latency
    const currentTime = Date.now() / 1000 - 10;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const encryptDataAES = (data, secretKey) => {
  if (!data || !secretKey) {
    throw new Error("Data and secret key are required for encryption");
  }

  if (secretKey.length !== 16) {
    throw new Error("Secret key must be 16 bytes for AES-128 encryption");
  }

  // Use a zero-filled IV
  const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

  // Encrypt the data using AES-CBC mode
  const ciphertext = CryptoJS.AES.encrypt(
    data, // plain text
    CryptoJS.enc.Utf8.parse(secretKey), // secret key as WordArray
    {
      iv: iv, // Zero-filled IV
      mode: CryptoJS.mode.CBC, // CBC mode
      padding: CryptoJS.pad.Pkcs7, // PKCS7 padding (compatible with PKCS5)
    }
  ).toString();

  return ciphertext;
};

export const encryptedSecret = (data, secretKey) => {
  if (!data || !secretKey) {
    throw new Error("Data and secret key are required for encryption");
  }

  if (secretKey.length !== 16) {
    throw new Error("Secret key must be 16 bytes for AES-128 encryption");
  }

  // Use a zero-filled IV
  const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

  // Encrypt the data using AES-CBC mode
  const ciphertext = CryptoJS.AES.encrypt(
    data, // plain text
    CryptoJS.enc.Utf8.parse(secretKey), // secret key as WordArray
    {
      iv: iv, // Zero-filled IV
      mode: CryptoJS.mode.CBC, // CBC mode
      padding: CryptoJS.pad.Pkcs7, // PKCS7 padding (compatible with PKCS5)
    }
  ).toString();

  return ciphertext;
};

export const getRemoteConfigValue = async (key) => {
  try {
    await fetchAndActivate(remoteConfig);

    const value = getValue(remoteConfig, key).asString();

    return value;
  } catch (error) {
    console.error("Error fetching remote config:", error);
    return null; // Fallback in case of error
  }
};
